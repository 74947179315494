<template>
  <div class="upload-idx">
    <van-cell-group>
      <template #title>
        <div style="text-align: left;">上传资料</div>
      </template>
      <van-field
        v-model="form.person_photo"
        label="个人影像"
        placeholder="未添加"
        readonly
        v-if="showPic"
        input-align="right"
        right-icon="arrow"
        @click="link('upload_picture')"
      />
      <van-field
        v-model="form.id_photo"
        label="身份证件"
        placeholder="未添加"
        input-align="right"
        readonly
        right-icon="arrow"
        @click="link('upload_id')"
      />
      <van-field
        v-model="form.address_info"
        label="住址"
        v-if="isCnId"
        readonly
        input-align="right"
        right-icon="arrow"
        @click="showSheet"
      />
      <van-field
        v-model="form.addr_photo"
        label="住址证明"
        v-if="form.address_info === '上传证明' || !isCnId"
        placeholder="未添加"
        readonly
        input-align="right"
        right-icon="arrow"
        @click="link('upload_adress')"
      />
      <van-field
        v-model="form.bank_photo"
        label="银行证明"
        placeholder="未添加"
        input-align="right"
        readonly
        right-icon="arrow"
        @click="link('upload_bank')"
      />
      <van-field
        v-model="form.asset_photo"
        label="个人资产证明"
        input-align="right"
        placeholder="未添加"
        readonly
        right-icon="arrow"
        v-if="showAssess"
        @click="link('upload_assets')"
      />
      <van-field
        v-model="form.transfer_photo"
        input-align="right"
        label="转账记录"
        label-width="5em"
        v-if="showPic"
        placeholder="未添加（添加后可加快审批）"
        readonly
        right-icon="arrow"
        @click="link('upload_transfer')"
      />
    </van-cell-group>
    <van-action-sheet
      v-model:show="isShowSheet"
      :actions="actions"
      @select="onSheetSelect"
      cancel-text="取消"
      close-on-click-action
    />
    <div style="width: 100%;text-align: center;">
      <van-button
        type="primary"
        class="btn"
        @click="next"
        :disabled="disabledNext || ((form.address_info === '上传证明' || !isCnId) && !form.addr_photo)"
      >
        提交
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, Field, ActionSheet,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, ref, reactive,
} from 'vue';
import httpApi from '../../utils/httpApi';

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      isShowSheet: false,
      actions: [{ name: '同身份证住址' }, { name: '上传证明' }],
    };
  },
  methods: {
    showSheet() {
      this.isShowSheet = true;
    },
    async onSheetSelect(value) {
      this.form.address_info = value.name;
      await httpApi.submitAddressInfo({ address: value.name });
      this.isShowSheet = false;
    },
    link(name) {
      this.$router.push({ name });
    },
    async next() {
      const res = this.$api.getAccountSubmit();
      if (res && res.code === 200) this.$toast('提交成功');
      this.$router.push({ path: '/upload/result' });
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const showPic = ref(false);
    const showAssess = ref(false);
    const disabledNext = ref(true);
    const isCnId = ref('');
    const form = reactive({
      major_invester: '',
      type: '',
      person_photo: '',
      id_photo: '',
      addr_photo: '',
      bank_photo: '',
      asset_photo: '',
      transfer_photo: '',

      address_info: '',
    });
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '上传资料');
      store.commit('setStepsValue', ['填写资料', '风险认知', '提交资料(2/2)']);
      store.commit('setStepsActive', 2);
      const res1 = await httpApi.getUploadImgesStatus();
      if (!res1) return;
      disabledNext.value = false;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (item === 'address_info') {
          form[item] = res1.data[item];
        } else if (res1.data[item] !== undefined) {
          form[item] = res1.data[item] ? '已添加' : '';
        }
      });
      ['id_photo', 'bank_photo'].forEach((item) => {
        if (res1.data[item] === 0) {
          disabledNext.value = true;
        }
      });
      showAssess.value = res1.data.major_invester === '是';
      showPic.value = res1.data.auth_type === '香港/合资格银行';
      if (showPic.value && !res1.data.person_photo) {
        disabledNext.value = true;
      }
      if (showAssess.value && !res1.data.asset_photo) {
        disabledNext.value = true;
      }
      /** 判断是否是中国大陆身份证 */
      const info = await httpApi.getOpenAccountInfo();
      if (!info) return;
      const isCnIdType = (info.data.type === '中国大陆身份证');
      isCnId.value = isCnIdType;
      /** 中国大陆默认同身份证住址  其他情况重置为上传证明 */
      if (isCnId.value) {
        if (!form.address_info) {
          await httpApi.submitAddressInfo({ address: '同身份证住址' });
          form.address_info = '同身份证住址';
        }
      } else {
        await httpApi.submitAddressInfo({ address: '上传证明' });
      }
    });
    return {
      isCnId,
      showPic,
      form,
      showAssess,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .upload-idx {
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
